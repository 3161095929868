<template>
  <div
    ref="ranking"
    class="histogram-container"
  >

  </div>
</template>

<script>
import * as echarts from 'echarts';
import config from "@/config/index.js";
export default {
  components: {

  },
  props: {
    //数据
    list: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      histogram: null,
      nameList: [],
      valueList: [],
      max: 0,
    };
  },
  computed: {

  },
  mounted() {
    // this.initData();
    window.addEventListener('resize', this.resizeHandler, false);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    //生成数据
    generateData() {
      this.nameList = [];
      this.valueList = [];
      this.max = 0;
      for (let i = 0; i < this.list.length; i++) {
        let item = this.list[i];
        this.nameList.unshift(item.search_content);
        this.valueList.unshift(item.counts);
        if (item.counts > this.max) this.max = item.counts;
      }
    },
    //初始化柱状图
    initData() {
      this.generateData();
      if (this.histogram == null) {
        this.histogram = echarts.init(this.$refs.ranking);
      }
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '10%',
          bottom: '3%',
          top: '6%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          max: this.max,
          boundaryGap: [0, 0.01],
          position: "bottom",
          splitNumber: 4,
          axisLine: {
            show: true,
          },
          axisTick: {
            interval: 0,
          },
          axisLabel: {
            hideOverlap: true,  //隐藏重叠的标签   
            fontSize: this.$adjustSize(12),
          }
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            fontSize: this.$adjustSize(12),
            width: this.$adjustSize(50),
            overflow: 'truncate',
          },
          data: this.nameList,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#FFFFFF',
            opacity: 0.2,
            width: 1,
          },
        },
        series: [
          {
            type: 'bar',
            // realtimeSort: true,
            barMaxWidth: this.$adjustSize(16),
            itemStyle: {
              //颜色渐变4个参数分别代表右下左上
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                { offset: 0, color: '#007FEB' },
                { offset: 1, color: '#60B6FF' }
              ]),

            },
            data: this.valueList,
          },
        ],
        animation: config.anim,
      };
      this.histogram.setOption(option, true);
    },
    resizeHandler() {
      if (this.histogram) {
        this.histogram.resize();
        this.initData();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.histogram-container {
  width: 280px;
  height: 100%;
}
</style>