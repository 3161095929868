/**
* 封装服务次数区间数据
* @param {*} maxValue 最大值
* @param {*} colorBox 区间颜色集
*/
export function generatePieces(maxValue, colorBox) {
   let pieces = [];
   let quotient = 1;
   let temp = {};
   temp.lt = 1;
   temp.label = '0';
   temp.color = colorBox[0];
   pieces.push(temp);

   if (maxValue && maxValue >= 4) {

       quotient = Math.floor(maxValue / 4);

       for (let i = 1; i <= 4; i++) {
           let temp = {};
           if (i == 1) {
               temp.gte = 1;
           } else {
               temp.gte = quotient * (i - 1);
           }
           temp.lte = quotient * i;
           temp.color = colorBox[i];
           // temp.label = '等级'+i;
           pieces.push(temp);
       }
   }

   return pieces;
}

/**
 * 获取最大值的整数
 * @param {*} max 最大值
 */
export function getRoundNum(max) {
    let bite = 0
    if (max < 100) {
        return 100
    }
    while (max >= 10) {
        max /= 10
        bite += 1
    }
    let result = Math.ceil(max) * Math.pow(10, bite)
    return parseInt(result);
}

/**
 * 产品列表图片path
 * @param {*} url 
 * @returns 
 */
 export const ListPic = function (url) {
  if (url) {
      return url + '?x-oss-process=style/P_List_Pic'
  }
  return url
}