// 世界对应的国家数据，key对应着后台的数据，name对应，worldGeo的name
const worldCountryData = {
    '不丹': { 'point': [89.647324, 27.476933], name: '不丹' },
    '东帝汶': { 'point': [125.564669, -8.549475], name: '东帝汶' },
    '中国': { 'point': [116.512885, 39.847469], name: '中国' },
    '中非': { 'point': [18.557903, 4.396116], name: '中非' },
    '丹麦': { 'point': [12.431735, 55.665973], name: '丹麦' },
    '乌克兰': { 'point': [30.512697, 50.452995], name: '乌克兰' },
    '乌兹别克斯坦': { 'point': [69.247259, 41.313586], name: '乌兹别克斯坦' },
    '乌干达': { 'point': [32.581738, 0.352894], name: '乌干达' },
    '乌拉圭': { 'point': [-56.163094, -34.893771], name: '乌拉圭' },
    '乍得': { 'point': [15.058995, 12.144253], name: '乍得' },
    '也门': { 'point': [44.188624, 15.375985], name: '也门' },
    // '亚美尼亚': { 'point': [], name: '亚美尼亚' },
    '以色列': { 'point': [35.21141, 31.77102], name: '以色列' },
    '伊拉克': { 'point': [44.363655, 33.314606], name: '伊拉克' },
    '伊朗': { 'point': [51.388399, 35.6899], name: '伊朗' },
    '伯利兹': { 'point': [12.849472, -4.571506], name: '伯利兹' },
    // '佛得角': { 'point': [], name: '佛得角' },
    '俄罗斯联邦': { 'point': [37.621612, 55.758257], name: '俄罗斯' },
    '保加利亚': { 'point': [23.318705, 42.69792], name: '保加利亚' },
    '克罗地亚': { 'point': [15.974196, 45.812166], name: '克罗地亚' },
    // '关岛': { 'point': [], name: '关岛' },
    '冈比亚': { 'point': [-16.582383, 13.457299], name: '冈比亚' },
    '冰岛': { 'point': [-21.859781, 64.099918], name: '冰岛' },
    '几内亚': { 'point': [-13.577316, 9.644511], name: '几内亚' },
    '几内亚比绍': { 'point': [-15.619766, 11.885767], name: '几内亚比绍' },
    // '列支敦士登': { 'point': [], name: '列支敦士登' },
    '刚果共和国': { 'point': [15.240923, -4.263634], name: '刚果布' },
    '利比亚': { 'point': [13.19135, 32.887237], name: '利比亚' },
    '利比里亚': { 'point': [-10.761016, 6.291152], name: '利比里亚' },
    '前南马其顿': { 'point': [21.427289, 41.997997], name: '马其顿' },
    '加拿大': { 'point': [-109.404347, 60.638178], name: '加拿大' },
    '加纳': { 'point': [-0.188114, 5.604581], name: '加纳' },
    '加蓬': { 'point': [9.495768, 0.436579], name: '加蓬' },
    '匈牙利': { 'point': [19.04196, 47.498109], name: '匈牙利' },
    // '北马里亚纳': { 'point': [], name: '北马里亚纳群岛' },
    '南乔治亚岛和南桑德韦奇岛': { 'point': [], name: '南乔治亚岛和南桑威奇群岛' },
    // '南极洲': { 'point': [], name: '南极洲共和国' },
    '南苏丹': { 'point': [31.586576, 4.891962], name: '' },
    '南非': { 'point': [18.480216, -33.873348], name: '南非' },
    // '博奈尔岛、圣尤斯达蒂斯和萨巴': { 'point': [], name: '' },
    '博茨瓦纳': { 'point': [25.967078, -24.62583], name: '博茨瓦纳' },
    '卡塔尔': { 'point': [51.531551, 25.286355], name: '卡塔尔' },
    '卢旺达': { 'point': [30.102346, -1.969667], name: '卢旺达' },
    '卢森堡': { 'point': [6.131308, 49.613029], name: '卢森堡' },
    '印度': { 'point': [77.206503, 28.62928], name: '印度' },
    '印度尼西亚': { 'point': [106.870937, -6.240893], name: '印度尼西亚' },
    '危地马拉': { 'point': [-90.543513, 14.649067], name: '危地马拉' },
    '厄瓜多尔': { 'point': [-78.467263, -0.179206], name: '厄瓜多尔' },
    '厄立特里亚': { 'point': [38.925716, 15.324804], name: '厄立特里亚' },
    '叙利亚': { 'point': [36.274228, 33.521994], name: '叙利亚' },
    '古巴': { 'point': [-82.366883, 23.119708], name: '古巴' },
    '吉尔吉斯斯坦': { 'point': [74.569345, 42.874887], name: '吉尔吉斯斯坦' },
    '吉布提': { 'point': [43.146919, 11.573827], name: '吉布提' },
    '哈萨克斯坦': { 'point': [71.470572, 51.160655], name: '哈萨克斯坦' },
    '哥伦比亚': { 'point': [-74.074104, 4.715315], name: '哥伦比亚' },
    '哥斯达黎加': { 'point': [-90.871819, 14.023867], name: '哥斯达黎加' },
    '喀麦隆': { 'point': [11.502655, 3.852758], name: '喀麦隆' },
    // '图瓦卢': { 'point': [], name: '' },
    '土库曼斯坦': { 'point': [58.338576, 38.057635], name: '土库曼斯坦' },
    '土耳其': { 'point': [32.853705, 39.941552], name: '土耳其' },
    // '圣卢西亚': { 'point': [], name: '圣卢西亚' },
    // '圣基茨和尼维斯': { 'point': [], name: '' },
    // '圣多美和普林西比': { 'point': [], name: '圣多美和普林西比' },
    // '圣巴泰勒米': { 'point': [], name: '' },
    // '圣文森特和格林纳丁斯': { 'point': [], name: '圣文森特和格林纳丁斯' },
    // '圣皮埃尔和密克隆群岛': { 'point': [], name: '圣皮埃尔和米克隆群岛' },
    // '圣诞岛': { 'point': [], name: '' },
    // '圣赫勒拿': { 'point': [], name: '圣赫勒拿' },
    // '圣马丁岛': { 'point': [], name: '' },
    // '圣马力诺': { 'point': [], name: '' },
    '圭亚那': { 'point': [-58.140752, 6.803865], name: '圭亚那' },
    '坦桑尼亚': { 'point': [35.698823, -6.211186], name: '坦桑尼亚' },
    '埃及': { 'point': [31.236287, 30.045171], name: '埃及' },
    '埃塞俄比亚': { 'point': [38.753839, 8.986474], name: '埃塞俄比亚' },
    // '基里巴斯': { 'point': [], name: '基里巴斯' },
    '塔吉克斯坦': { 'point': [68.788383, 38.572383], name: '塔吉克斯坦' },
    '塞内加尔': { 'point': [-17.367747, 14.764454], name: '塞内加尔' },
    '塞尔维亚': { 'point': [20.446144, 44.786894], name: '塞尔维亚' },
    '塞拉利昂': { 'point': [-13.233425, 8.466744], name: '塞拉利昂' },
    '塞浦路斯': { 'point': [33.380264, 35.183442], name: '塞浦路斯' },
    '塞舌尔': { 'point': [55.7, -3.8], name: '塞舌尔' },
    '墨西哥': { 'point': [-99.134543, 19.433793], name: '墨西哥' },
    '多哥': { 'point': [1.244889, 6.219695], name: '多哥' },
    '多米尼加': { 'point': [-80.245123, 22.580836], name: '多米尼加' },
    // '奥兰群岛': { 'point': [], name: '奥兰群岛' },
    '奥地利': { 'point': [16.373532, 48.209137], name: '奥地利' },
    '委内瑞拉': { 'point': [-66.90346, 10.492688], name: '委内瑞拉' },
    '孟加拉国': { 'point': [90.410568, 23.813179], name: '孟加拉' },
    '安哥拉': { 'point': [13.285773, -8.748538], name: '安哥拉' },
    // '安圭拉': { 'point': [], name: '' },
    // '安提瓜和巴布达': { 'point': [], name: '安提瓜和巴布达' },
    // '安道尔': { 'point': [], name: '安道尔' },
    // '密克罗尼西亚联邦': { 'point': [], name: '密克罗尼西亚' },
    '尼加拉瓜': { 'point': [-86.236749, 12.115559], name: '尼加拉瓜' },
    '尼日利亚': { 'point': [7.459498, 9.126437], name: '尼日利亚' },
    '尼日尔': { 'point': [2.118056, 13.516354], name: '尼日尔' },
    '尼泊尔': { 'point': [85.320511, 27.711], name: '尼泊尔' },
    // '巴勒斯坦': { 'point': [], name: '巴勒斯坦' },
    '巴哈马': { 'point': [-73.562575, 40.655271], name: '巴哈马' },
    '巴基斯坦': { 'point': [73.076186, 33.714975], name: '巴基斯坦' },
    // '巴巴多斯': { 'point': [], name: '巴巴多斯' },
    '巴布亚新几内亚': { 'point': [147.27892, -9.465948], name: '巴布亚新几内亚' },
    '巴拉圭': { 'point': [-57.576213, -25.260342], name: '巴拉圭' },
    '巴拿马': { 'point': [-79.401974, 9.102657], name: '巴拿马' },
    // '巴林': { 'point': [], name: '巴林' },
    '巴西': { 'point': [-47.888777, -15.791724], name: '巴西' },
    '布基纳法索': { 'point': [-1.521672, 12.375667], name: '布基纳法索' },
    // '布维岛': { 'point': [], name: '' },
    // '布隆迪': { 'point': [], name: '布隆迪' },
    '希腊': { 'point': [23.72821, 37.9846], name: '希腊' },
    // '帕劳': { 'point': [], name: '帕劳' },
    // '库克群岛': { 'point': [], name: '' },
    // '库拉索': { 'point': [], name: '' },
    // '开曼群岛': { 'point': [], name: '开曼群岛' },
    '德国': { 'point': [13.40498, 52.521199], name: '德国' },
    '意大利': { 'point': [12.491267, 41.904612], name: '意大利' },
    '所罗门群岛': { 'point': [159.981803, -9.43537], name: '所罗门群岛' },
    // '扎伊尔': { 'point': [], name: '' },
    // '托克劳': { 'point': [], name: '' },
    '拉脱维亚': { 'point': [24.105101, 56.949378], name: '拉脱维亚' },
    '挪威': { 'point': [4.89354, 52.370649], name: '挪威' },
    '捷克': { 'point': [116.413384, 39.910925], name: '捷克' },
    '摩尔多瓦': { 'point': [28.858505, 47.012344], name: '摩尔多瓦' },
    '摩洛哥': { 'point': [-6.862605, 33.977609], name: '摩洛哥' },
    // '摩纳哥': { 'point': [], name: '' },
    '文莱': { 'point': [114.951828, 4.948475], name: '文莱' },
    '斐济': { 'point': [178.450079, -18.122335], name: '斐济' },
    '斯威士兰': { 'point': [31.134648, -26.292261], name: '斯威士兰' },
    '斯洛伐克': { 'point': [17.099623, 48.141697], name: '斯洛伐克' },
    // '斯洛文尼亚': { 'point': [], name: '斯洛文尼亚' },
    // '斯瓦尔巴岛和扬马延岛': { 'point': [], name: '' },
    '斯里兰卡': { 'point': [79.865104, 6.909415], name: '斯里兰卡' },
    '新加坡': { 'point': [103.413384, 1.910925], name: '新加坡' },
    '新喀里多尼亚': { 'point': [166.449312, -22.251664], name: '新喀里多尼亚' },
    '新西兰': { 'point': [174.761827, -41.291661], name: '新西兰' },
    '日本': { 'point': [139.713657, 35.707004], name: '日本' },
    '智利': { 'point': [-70.693344, 19.48165], name: '智利' },
    '朝鲜': { 'point': [125.760512, 39.041685], name: '朝鲜' },
    '柬埔寨': { 'point': [104.891769, 11.545102], name: '柬埔寨' },
    // '根西岛': { 'point': [], name: '' },
    // '格林纳达': { 'point': [], name: '格林纳达' },
    '格陵兰': { 'point': [-51.573608, 64.213084], name: '格陵兰' },
    '格鲁吉亚': { 'point': [44.827351, 41.71589], name: '格鲁吉亚' },
    // '梵蒂冈': { 'point': [], name: '' },
    '比利时': { 'point': [4.342799, 50.85052], name: '比利时' },
    // '毛利塔尼亚': { 'point': [], name: '毛里塔尼亚' },
    // '毛里求斯': { 'point': [], name: '毛里求斯' },
    // '汤加': { 'point': [], name: '汤加' },
    '沙特阿拉伯': { 'point': [46.672152, 24.714184], name: '沙特阿拉伯' },
    '法国': { 'point': [2.333696, 48.865244], name: '法国' },
    // '法属南部领地': { 'point': [], name: '' },
    // '法属圣马丁': { 'point': [], name: '' },
    // '法属圭亚那': { 'point': [], name: '' },
    '法属波利尼西亚': { 'point': [], name: '法属波利尼西亚' },
    '法罗群岛': { 'point': [], name: '法罗群岛' },
    '波兰': { 'point': [21.021333, 52.232842], name: '波兰' },
    '波多黎各': { 'point': [120.809359, 17.750942], name: '波多黎各' },
    // '波黑': { 'point': [], name: '' },
    '泰国': { 'point': [100.451117, 13.724061], name: '泰国' },
    // '泽西岛': { 'point': [], name: '泽西岛' },
    '津巴布韦': { 'point': [31.033189, -17.824573], name: '津巴布韦' },
    '洪都拉斯': { 'point': [-87.253756, 14.044503], name: '洪都拉斯' },
    '海地': { 'point': [-72.307002, 18.595629], name: '海地' },
    '澳大利亚': { 'point': [137.708144, -25.328065], name: '澳大利亚' },
    '爱尔兰': { 'point': [-6.259735, 53.350324], name: '爱尔兰' },
    '爱沙尼亚': { 'point': [24.75379, 59.439569], name: '爱沙尼亚' },
    '牙买加': { 'point': [140.433549, -36.466221], name: '牙买加' },
    // '特克斯和凯科斯群岛': { 'point': [], name: '特克斯和凯克特斯群岛' },
    '特立尼达和多巴哥': { 'point': [-61.528354, 10.674498], name: '特立尼达和多巴哥' },
    '玻利维亚': { 'point': [-78.332036, 22.082519], name: '玻利维亚' },
    // '瑙鲁': { 'point': [], name: '' },
    '瑞典': { 'point': [18.058376, 59.327783], name: '瑞典' },
    '瑞士': { 'point': [7.44716, 46.950139], name: '瑞士' },
    // '瓜德罗普': { 'point': [], name: '' },
    // '瓦利斯和富图纳': { 'point': [], name: '' },
    '瓦努阿图': { 'point': [168.327477, -17.730243], name: '瓦努阿图' },
    // '留尼汪': { 'point': [], name: '' },
    '白俄罗斯': { 'point': [27.564451, 53.975825], name: '白俄罗斯' },
    '百慕大': { 'point': [-86.054972, 40.038827], name: '百慕大' },
    // '皮特凯恩': { 'point': [], name: '' },
    // '直布罗陀': { 'point': [], name: '' },
    '福克兰群岛（马尔维纳斯）': { 'point': [-57.940098, -51.615196], name: '福克兰群岛' },
    '科威特': { 'point': [47.977439, 29.376031], name: '科威特' },
    // '科摩罗': { 'point': [], name: '科摩罗' },
    // '科科斯（基林）群岛': { 'point': [], name: '' },
    '科索沃': { 'point': [21.163778, 42.663444], name: '' },
    '秘鲁': { 'point': [-77.042548, -12.044223], name: '秘鲁' },
    '突尼斯': { 'point': [10.208651, 36.866028], name: '突尼斯' },
    '立陶宛': { 'point': [25.279069, 54.687457], name: '立陶宛' },
    '索马里': { 'point': [45.317204, 2.047013], name: '索马里' },
    '约旦': { 'point': [35.927003, 31.946341], name: '约旦' },
    '纳米比亚': { 'point': [17.034567, -22.555179], name: '纳米比亚' },
    '纽埃': { 'point': [31.15, 30.03], name: '纽埃' },
    '缅甸': { 'point': [96.077119, 19.763943], name: '缅甸' },
    '罗马尼亚': { 'point': [51.531551, 25.286355], name: '罗马尼亚' },
    '美国': { 'point': [-73.97622, 40.757498], name: '美国' },
    // '美国本土外小岛屿': { 'point': [], name: '' },
    // '美属维尔京群岛': { 'point': [], name: '维尔京群岛，美属' },
    // '美属萨摩亚': { 'point': [], name: '美属萨摩亚' },
    '老挝': { 'point': [102.632451, 17.975794], name: '老挝' },
    '肯尼亚': { 'point': [36.820997, -1.291346], name: '肯尼亚' },
    '芬兰': { 'point': [24.935506, 60.173495], name: '芬兰' },
    '苏丹': { 'point': [32.560519, 15.501806], name: '苏丹' },
    '苏里南': { 'point': [31.586576, 4.891962], name: '苏里南' },
    '英国': { 'point': [-0.130058, 51.508604], name: '英国' },
    // '英国属地曼岛': { 'point': [], name: '' },
    // '英属印度洋领地': { 'point': [], name: '英属印度洋领地' },
    // '英属维尔京群岛': { 'point': [], name: '' },
    '荷兰': { 'point': [4.89354, 52.370649], name: '荷兰' },
    '莫桑比克': { 'point': [32.602247, -25.890353], name: '莫桑比克' },
    '莱索托': { 'point': [29.085487, -29.04088], name: '莱索托' },
    '菲律宾': { 'point': [120.985715, 14.603576], name: '菲律宾' },
    '萨尔瓦多': { 'point': [-89.22758, 13.692121], name: '萨尔瓦多' },
    // '萨摩亚': { 'point': [], name: '萨摩亚' },
    '葡萄牙': { 'point': [-9.161365, 38.768409], name: '葡萄牙' },
    '蒙古': { 'point': [106.908677, 47.915632], name: '蒙古' },
    // '蒙特塞拉特': { 'point': [], name: '蒙特塞拉特' },
    '西撒哈拉': { 'point': [-13.233061, 27.246935], name: '西撒哈拉' },
    '西班牙': { 'point': [-3.703414, 40.419467], name: '西班牙' },
    // '诺福克岛': { 'point': [], name: '' },
    // '象牙海岸': { 'point': [], name: '' },
    '贝宁': { 'point': [2.626232, 6.499347], name: '贝宁' },
    '赞比亚': { 'point': [28.321954, -15.376648], name: '赞比亚' },
    '赤道几内亚': { 'point': [8.736346, 3.749414], name: '赤道几内亚' },
    // '赫德岛和麦克唐纳岛': { 'point': [], name: '赫德和麦克唐纳群岛' },
    '越南': { 'point': [105.699615, 20.973967], name: '越南' },
    '阿塞拜疆': { 'point': [49.867851, 40.409897], name: '阿塞拜疆' },
    '阿富汗': { 'point': [69.132386, 34.739287], name: '阿富汗' },
    '阿尔及利亚': { 'point': [3.058874, 36.755571], name: '阿尔及利亚' },
    '阿尔巴尼亚': { 'point': [19.809499, 41.389924], name: '阿尔巴尼亚' },
    '阿曼': { 'point': [58.405562, 23.587133], name: '阿曼' },
    '阿根廷': { 'point': [-64.388276, -31.379492], name: '阿根廷' },
    '阿联酋': { 'point': [54.662609, 24.390891], name: '阿拉伯联合酋长国' },
    // '阿鲁巴': { 'point': [], name: '' },
    '韩国': { 'point': [126.986407, 37.536804], name: '韩国' },
    // '马尔代夫': { 'point': [], name: '' },
    '马拉维': { 'point': [33.774057, -13.961542], name: '马拉维' },
    // '马提尼克': { 'point': [], name: '' },
    '马来西亚': { 'point': [101.681865, 3.136134], name: '马来西亚' },
    // '马约特': { 'point': [], name: '' },
    // '马绍尔群岛': { 'point': [], name: '' },
    // '马耳他': { 'point': [], name: '马耳他' },
    '马达加斯加': { 'point': [47.508608, -18.867016], name: '马达加斯加' },
    '马里': { 'point': [-8.003581, 12.640388], name: '马里' },
    '黎巴嫩': { 'point': [35.500052, 33.89439], name: '黎巴嫩' },
    '黑山': { 'point': [19.261583, 42.435497], name: '黑山' },
};
export default worldCountryData;
