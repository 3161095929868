<template>
  <div
    ref="worldMap"
    class="world-map-container"
  ></div>
</template>

<script>
import * as echarts from 'echarts';
import worldGeo from "./../data/worldGeo.js"
import worldCountry from "./../data/worldCountry.js";
import { getRoundNum, generatePieces } from "@/libs/utils.js";
import config from "@/config/index.js";
// http://ppchart.com/ 地图组件文档
export default {
  props: {
    //霄鸟坐标
    xiaoniao: {
      type: Array,
      default: function () {
        return [];
      },
    },
    //地图数据，格式：[ { region_name: '广东', num: 560 }, { region_name: '江苏省', num: 500 } ]，    region_name对应worldProvincial的key
    worldMapData: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  components: {

  },
  data() {
    return {
      worldMap: null,    //世界地图的echarts对象
      worldMapLineData: [], //线的数据 [{ coords: [ this.xiaoniao, [127.9688, 45.368], ], lineStyle: { color: "#4fb6d2" }, },]
      worldMapPoints: [],   //国家的点的数据    [{ value: [118.8062, 31.9208], itemStyle: { color: "#4ab2e5" } },]
      worldMapDifficultData: [],    //热力图数据  [{ name: '中国', value: 123 }]
      max: 0,
    };
  },
  mounted() {
    // this.initWorldMap();
    window.addEventListener('resize', this.resizeHandler, false);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
    if(this.worldMap != null) this.worldMap.dispose();
  },
  methods: {
    //生成世界地图的数据
    generateWorldMapData() {
      this.worldMapPoints = [];
      this.worldMapLineData = [];
      this.worldMapDifficultData = [];
      if (this.worldMapData.length == 0) return false;
      this.max = getRoundNum(this.worldMapData[0]['num']);
      for (let i = 0; i < this.worldMapData.length; i++) {
        let item = this.worldMapData[i];
        let coordinatePoint = this.getCoordinatePoint(item.region_name);    //坐标
        let regionNameName = this.getRegionNameName(item.region_name);  //name
        if (coordinatePoint == false) continue;    //坐标拿不到跳过

        //点的数据
        this.worldMapPoints.push({ value: coordinatePoint, itemStyle: { color: '#2A5CA5' }, name: regionNameName, num: item.num });
        //线的数据
        this.worldMapLineData.push({
          coords: [this.xiaoniao, coordinatePoint],
          lineStyle: { color: '#e7ab0b' },
        });
        this.worldMapDifficultData.push({ name: regionNameName, value: item.num });

      }
    },
    getCoordinatePoint(region_name) {
      if (worldCountry[region_name] != undefined) {
        return worldCountry[region_name]['point'];
      } else {
        return false;
      }
    },
    getRegionNameName(region_name) {
      if (worldCountry[region_name] != undefined) {
        return worldCountry[region_name]['name'];
      } else {
        return false;
      }
    },
    //初始化世界地图
    initWorldMap() {
      this.generateWorldMapData();
      if (this.worldMap == null) {
        this.worldMap = echarts.init(this.$refs.worldMap);
      }
      this.worldMap.showLoading();
      let index = -1;

      echarts.registerMap("world", { geoJson: worldGeo });
      this.worldMap.hideLoading();
      let option = {

        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            // console.log(params)
            let num = 0;
            let name = params.name;
            if (params.componentSubType != undefined) {
              if (params.componentSubType == 'map' && params.data != undefined && params.data.value != undefined) {
                num = params.data.value;
              } else if (params.componentSubType == "scatter" && params.data != undefined && params.data.name != undefined) {
                name = params.data.name;
                num = params.data.num;
              } else if (params.componentSubType == "lines") {
                return false;
              } else if (params.componentSubType == "custom" && params.seriesName == '霄鸟云') {
                return "霄鸟云";
              }
            }

            return name + ' : ' + num;
          }
        },
        visualMap: {
          min: 1,
          max: this.max,
          left: this.$adjustSize(41),
          seriesIndex: 0,
          type: 'piecewise',
          bottom: this.$adjustSize(54),
          textStyle: {
            color: '#FFFF'
          },
          splitNumber: 4,
          minOpen: true,
          precision: 0,
          //   inRange: {
          //     color: ['#7BB3FF', '#3373CD', '#2A5CA5', '#1C3969', '#16294A'],
          //   },
          pieces: generatePieces(this.max, ['#7BB3FF', '#3373CD', '#2A5CA5', '#1C3969', '#16294A']),
          itemWidth: this.$adjustSize(13),
          itemHeight: this.$adjustSize(13),
          textStyle: {
            color: '#FFFFFF',
            fontSize: this.$adjustSize(12),
          },
        },

        geo: {
          map: "world",
          aspectScale: 0.75, //长宽比
          zoom: 1.1,
          roam: false,
          itemStyle: {
            normal: {
              areaColor: {
                type: "radial",
                x: 0.5,
                y: 0.5,
                r: 0.8,
                colorStops: [
                  {
                    offset: 0,
                    color: "#09132c", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#274d68", // 100% 处的颜色
                  },
                ],
                globalCoord: true, // 缺省为 false
              },
              //   shadowColor: "rgb(58,115,192)",
              //   shadowOffsetX: 10,
              //   shadowOffsetY: 11,
            },
            emphasis: {
              areaColor: "#2AB8FF",
              borderWidth: 0,
              color: "green",
              label: {
                show: false,
              },
            },
          },
          regions: [
            {
              name: "南海诸岛",
              itemStyle: {
                areaColor: "rgba(0, 10, 52, 1)",

                borderColor: "rgba(0, 10, 52, 1)",
                normal: {
                  opacity: 0,
                  label: {
                    show: false,
                    color: "#009cc9",
                  },
                },
              },
            },
          ],
        },
        series: [
          {
            type: "map",
            roam: false,
            label: {
              normal: {
                show: false,
                textStyle: {
                  color: "#1DE9B6",
                },
              },
              emphasis: {
                //   show: false,
                textStyle: {
                  color: "rgb(183,185,14)",
                },
              },
            },

            itemStyle: {
              normal: {
                borderWidth: 1,
                areaColor: "#7BB3FF",
                borderColor: "#FFFFFF",
              },
              emphasis: {
                areaColor: "rgb(46,229,206)",
                //    shadowColor: 'rgb(12,25,50)',
                borderWidth: 0.1,
              },
            },
            zoom: 1.1,
            // roam: false,
            map: "world", //使用
            nameProperty: 'name',
            data: this.worldMapDifficultData //热力图数据   不同区域 不同的底色
          },
          //点
          {
            type: "scatter",
            coordinateSystem: "geo",
            showEffectOn: "render",
            zlevel: 1,
            rippleEffect: {
              period: 15,
              scale: 4,
              brushType: "fill",
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: "{b}",
                position: "right",
                offset: [15, 0],
                color: "#1DE9B6",
                show: false,
              },
            },
            itemStyle: {
              normal: {
                // color: "#1DE9B6"
                /* function (value){ //随机颜色
                    return "#"+("00000"+((Math.random()*16777215+0.5)>>0).toString(16)).slice(-6);
                }*/
                // ,
                // shadowBlur: 10,
                // shadowColor: "#333",
                borderColor: '#FFFFFF',
                borderWidth: 2,

              },
            },
            symbolSize: 7,  //圆点大小
            data: this.worldMapPoints,
          }, //地图线的动画效果
          //线
          {
            type: "lines",
            zlevel: 2,
            effect: {
              show: config.anim,
              period: 4, //箭头指向速度，值越小速度越快
              trailLength: 0.2, //特效尾迹长度[0,1]值越大，尾迹越长重
              symbol: "circle", //箭头图标
              symbolSize: 4, //图标大小
            },
            lineStyle: {
              normal: {
                color: "#1DE9B6",
                /* function (value){ //随机颜色   
                        ['#f21347','#f3243e','#f33736','#f34131','#f34e2b',
                        '#f56321','#f56f1c','#f58414','#f58f0e','#f5a305',
                        '#e7ab0b','#dfae10','#d5b314','#c1bb1f','#b9be23',
                        '#a6c62c','#96cc34','#89d23b','#7ed741','#77d64c',
                        '#71d162','#6bcc75','#65c78b','#5fc2a0','#5abead',
                        '#52b9c7','#4fb6d2','#4ab2e5']
                        return "#"+("00000"+((Math.random()*16777215+0.5)>>0).toString(16)).slice(-6);
                    }*/
                width: 1, //线条宽度
                // opacity: 0.1, //尾迹线条透明度
                opacity: config.anim ? 0.1 : 0.7,
                curveness: 0.3, //尾迹线条曲直度
              },
            },
            data: this.worldMapLineData,
          },
          //霄鸟云的图标
          {
            name: "霄鸟云",
            type: "custom", //配置显示方式为用户自定义
            zlevel: 3,
            coordinateSystem: "geo",
            itemStyle: {
              normal: {
                color: '#fff'
              }
            },
            renderItem: (params, api) => {
              //具体实现自定义图标的方法
              let point = api.coord([api.value(0), api.value(1)]);
              return {
                type: "image",
                style: {
                  image: require('@/assets/images/xiaoniao.png'),
                  x: point[0] - this.$adjustSize(15),
                  y: point[1] - this.$adjustSize(29),
                  width: this.$adjustSize(30),
                }
              };
            },
            data: [this.xiaoniao],
          },
        ],
        animation: config.anim,
      };
      this.worldMap.setOption(option, true);
      this.worldMap.resize();
    },
    resizeHandler() {
      if (this.worldMap) {
        this.worldMap.resize();
        this.initWorldMap();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
</style>