<template>
  <div class="online-factory">
    <div class="online-factory-title"></div>
    <div class="online-factory-table">
      <vue-seamless-scroll
        ref="actualTimeOnlineFactory"
        :data="onlineFactoryData"
        :class-option="optionHover"
        v-if="config.anim"
      >
        <ul
          class="td"
          v-for="(item, i) in onlineFactoryData"
          :key="i"
        >
          <li>{{item.name}}</li>
        </ul>
      </vue-seamless-scroll>
      <div
        v-else
      >
        <ul
          class="td"
          v-for="(item, i) in onlineFactoryDataOnePage"
          :key="i"
        >
          <li>{{item.name}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getFactoryRealtime } from "@/api/index.js";
import { useTimer } from "@/libs/timer.js";
import { useDataPage } from "@/libs/dataPage.js";
import config from "@/config/index.js";
export default {
  components: {

  },
  props: {
    
  },
  data() {
    return {
      config,
      optionHover: {
        step: 0.5, //数值阅大滚动越快
        limitMoveNum: 5,  //开始无缝滚动的数据量 data.length
        hoverStop: true, //鼠标悬停
        direction: 1, //0向下，1向上，2向左，3向右
        openWatch: true,    //开启数据实时监控刷新dom
        singleHeight: 0,    //单步运动停止的高度（默认0，无缝不停的滚动）
        singleWidth: 0, //单步运动停止的宽度（默认0，无缝不停的滚动）
        waitTime: 1000, //单步运动停止的时间（默认1000ms）
      },
      //在线工厂
      onlineFactoryData: [],
      onlineFactoryDataOnePage: [],
      timer: null,
      dataPage: null,
    };
  },
  computed: {

  },
  created(){
    if(!config.anim){ //没有动画用切换渲染数据
      this.timer = useTimer();
      this.dataPage = useDataPage(5);
      this.timer.addEvent();
    }
  },
  mounted() {
    
  },
  beforeDestroy() {
    if(!config.anim){
      //销毁定时器
      this.timer.destroy();
      this.timer.removeEvent();
    }
  },
  methods: {
    initData(refresh){
      let postData = { refresh: refresh };
      if(!config.anim) postData['pageSize'] = 50;
      getFactoryRealtime(postData).then(res => {
        if (res.code == 0) {
          this.onlineFactoryData = [];
          this.$nextTick(() => {
            this.onlineFactoryData = res.data.data;
            if(!config.anim){
              this.dataPage.init(this.onlineFactoryData);
              this.timer.init(()=>{
                this.onlineFactoryDataOnePage = this.dataPage.next();
              });
              this.onlineFactoryDataOnePage = this.dataPage.next();
              this.timer.start();
            }
          })
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.online-factory {
  width: 277px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .online-factory-title {
    height: 39px;
    background: url(~@/assets/images/online_factory_title.png)
      no-repeat;
    background-size: 100% 100%;
  }
  .online-factory-table {
    flex: 1;
    background: url(~@/assets/images/bottom_2.png)
      rgba(18, 42, 62, 0.4) no-repeat center bottom;
    border: 0.5px solid rgba(94, 204, 255, 0.4);
    background-size: 278px 12px;
    overflow: hidden;
    .td {
      background: url(~@/assets/images/line_2.png) no-repeat center
        bottom;
      li {
        width: 100%;
      }
    }
  }
  @import "~@/assets/scss/actual-time.scss";
}
</style>